<!--
### Textarea form input
 -->
<script lang="ts">
  import autosize from "autosize";
  import { onMount, type ComponentProps } from "svelte";
  import FormField from "../FormField/FormField.svelte";
  import type { HTMLTextareaAttributes } from "svelte/elements";

  type TextareaProps = {
    // The value of the textarea
    value?: string;
    // Whether the textarea is minimal
    minimal?: boolean;
  } & ComponentProps<typeof FormField> &
    HTMLTextareaAttributes;

  let {
    value = $bindable(),
    minimal = false,
    label,
    layout = "normal",
    error,
    tip,
    class: className,
    ...props
  }: TextareaProps = $props();

  let textarea: HTMLTextAreaElement;

  onMount(() => {
    autosize(textarea);
  });
</script>

<style>
  .minimal {
    border: none;
  }

  textarea {
    width: 100%;
  }
</style>

<FormField {label} {error} {tip} {layout} class={className}>
  <textarea
    bind:this={textarea}
    bind:value
    class="--input"
    class:minimal
    {...props}
  ></textarea>
</FormField>
